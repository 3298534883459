import React from "react"
import { Waypoint } from 'react-waypoint';

import {handleWaypointIn} from "../../utilities/waypoint"

const ContactForm = () => { 

    return (
        <form action="https://www.elformo.com/forms/76e8e302-1587-4ce3-9fec-8f318d94ab9d" method="post" acceptCharset="UTF-8">
            <div className="form-row split">
                <label>Name<span>*</span><br/>
                    <input id="name" name="name" type="text" required />
                </label>
                <label>Email<span>*</span><br/>
                    <input id="email" name="email" type="email" required />
                </label>                                
            </div>
            
            <div className="form-row">
            <label>Message<span>*</span><br/>
                <textarea id="comment" name="comment" required></textarea>
            </label>                             
        </div>   

        <div className="form-row">
            <Waypoint onEnter={() => handleWaypointIn('contact-button', 'jello-vertical')}>         
                <div id="contact-button">                    
                    <button className="button-black">
                        Send
                    </button>    
                </div>    
            </Waypoint>                                     
        </div>   
            
        </form>
    )
}  
  

export default ContactForm