export const handleWaypointIn = (elementId, style = 'fade-in-bottom') => { 
    const element = document.getElementById(elementId)
    element.classList.add(style)
}

export const handleWaypointOut = (elementId, style = 'fade-in-bottom') => { 
    const element = document.getElementById(elementId)
    element.classList.remove(style)
}    

